
@font-face {
    font-family: 'Karlsen Round Bold';
    src: url('../fonts/karlsenround-bold-webfont.eot');
    src: url('../fonts/karlsenround-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/karlsenround-bold-webfont.woff2') format('woff2'),
         url('../fonts/karlsenround-bold-webfont.woff') format('woff'),
         url('../fonts/karlsenround-bold-webfont.ttf') format('truetype'),
         url('../fonts/karlsenround-bold-webfont.svg#karlsenround-bolduploadedfile') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Karlsen Round Light';
    src: url('../fonts/karlsenround-light-webfont.eot');
    src: url('../fonts/karlsenround-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/karlsenround-light-webfont.woff2') format('woff2'),
         url('../fonts/karlsenround-light-webfont.woff') format('woff'),
         url('../fonts/karlsenround-light-webfont.ttf') format('truetype'),
         url('../fonts/karlsenround-light-webfont.svg#karlsenround-lighuploadedfile') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Karlsen Round Regular';
    src: url('../fonts/karlsenround-regular-webfont.eot');
    src: url('../fonts/karlsenround-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/karlsenround-regular-webfont.woff2') format('woff2'),
         url('../fonts/karlsenround-regular-webfont.woff') format('woff'),
         url('../fonts/karlsenround-regular-webfont.ttf') format('truetype'),
         url('../fonts/karlsenround-regular-webfont.svg#karlsenround-reguuploadedfile') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Karlsen Round Semi Bold';
    src: url('../fonts/karlsenround-semibold-webfont.eot');
    src: url('../fonts/karlsenround-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/karlsenround-semibold-webfont.woff2') format('woff2'),
         url('../fonts/karlsenround-semibold-webfont.woff') format('woff'),
         url('../fonts/karlsenround-semibold-webfont.ttf') format('truetype'),
         url('../fonts/karlsenround-semibold-webfont.svg#karlsenround-semiuploadedfile') format('svg');
    font-weight: normal;
    font-style: normal;

}