/*
| Radio Jajce
| Main CSS File
| Copyright 2015 David Cavar Lacic
*/
@import "karlsenround.scss";

@function emCalc($pxwidth) {
	@return $pxwidth / 16 * 1em
}

$blue: #243757;

@mixin transition($duration) {
	-webkit-transition: $duration ease-in-out;
	-moz-transition: $duration ease-in-out;
	-o-transition: $duration ease-in-out;
	transition: $duration ease-in-out;
}

body {
	font-family: 'Karlsen Round Regular' !important;
}
header {
	&.home {
		position: fixed;
		height: 90px;
		width: 100%;
		z-index: 100;
		padding: 0;
		.row {
			max-width: 62.5em !important;
		}
		@include transition(.3s);
		h1 {
			background: url('../img/logo.svg') no-repeat;

		}
		nav {
			float: right;
			ul {
				font-family: 'Karlsen Round Bold' !important;
				line-height: 90px;
				li {
					a {
						color: #fff;
						&:hover {
							color: #fff;
						}
					}
					&:hover {
						background: rgba(255,255,255,.5)
					}
					&.active {
						background: #fff;
						a {
							color: $blue;
						}
					}
					&.light-bg-active {
						background: $blue;
						a {
							color: #fff;
						}
					}
				}
			}
		}
		&.smaller {
		background: #fff;
		height: 80px;
		h1 {
			background: url('../img/logo_small.svg') no-repeat;
			height: 65px;
		}
		ul {
			font-family: 'Karlsen Round Bold' !important;
			line-height: 80px;
			a {
				color: $blue;
				&:hover {
					color: $blue;
				}
			}
			li {
				&:hover {
					background: rgba(39, 55, 87, .5);
				}
				padding: 0 20px;
				&.active {
					background: $blue;
					a {
						color: #fff;
					}
				}
			}
		}
	}
	}
	padding: 30px;
	.row {
		max-width: 100% !important;
	}
	h1 {
		width: 124px;

		text-indent: -9999px;
		line-height: 90px;
		margin-top: 6px;
		background: url('../img/logo_small.svg') no-repeat;
		height: 65px;
	}
	nav {
		float: right;
		ul {
			font-family: 'Karlsen Round Bold' !important;
			line-height: 90px;
			li {
				@include transition(.3s);
				padding: 0 20px;
				width: 125px;
				margin: 0 !important;
				text-align: center;
			}
			a {
				color: $blue;
				&:hover {
					color: $blue;
				}
			}
			li {
				&:hover {
					background: rgba(39, 55, 87, .5);
				}
				padding: 0 20px;
				&.active {
					background: $blue;
					a {
						color: #fff;
					}
				}
			}
		}
	}
}
.intro-video {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	color: #fff !important;
	font-family: 'Karlsen Round Bold' !important;
	text-shadow: 0 20px 70px rgba(0, 0, 0, 0.6);
	video {
		position: absolute;
		min-height: 100%;
		width: 100%;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -100;
		object-fit: cover;
	}
	.intro_text {
		max-width: 1000px;
		height: 100%;
		margin: 0px auto;
		text-align: center;
		h2 {
			color: #fff !important;
			font-family: 'Karlsen Round Bold' !important;
			font-size: emCalc(90);
			line-height: 1;
			margin-top: 25px;
		}
	}
	.button {
		background: transparent;
		border: 2px solid #fff;
		font-family: 'Karlsen Round Semi Bold' !important;
		text-transform: uppercase;
		@include transition(.3s);
		&:hover {
			background: #fff;
			color: $blue;
		}
	}
	&:after {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: url('../img/video_overlay.png');
		content: '';
		z-index: -99;
	}
}
.mail-message {
	background-color: #a0d3e8;
    border-color: #74bfdd;
    color: #4f4f4f;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}
.wide {
	background: transparent;
	color: $blue;
	border: 2px solid $blue;
	font-family: 'Karlsen Round Semi Bold' !important;
	text-transform: uppercase;
	margin-top: 25px;
	width: 100%;
	&:hover {
			background: $blue;
			color: #fff;
	}
}

.t {
	display: table;
	width: 100%;
	height: 100%;
	margin: 0 auto;
}
.td {
	display: table-cell;
	vertical-align: middle;
}

#player_hidden {
	display: none;
}

.bottom_block {
	height: 150px;
	text-align: center;
	font-size: emCalc(24);
	color: #fff;
	@include transition(.3s);
	font-family: 'Karlsen Round Semi bold';
	&:hover {
		text-shadow: 0 20px 70px rgba(0, 0, 0, 0.6);
		box-shadow: 0px 0px 20px rgba(0,0,0,.5);
	}
}

.podcast {
	background: url('../img/podcast.jpg') top center;
	background-size: cover;
}

.jconline {
	background: url('../img/jajceonline.jpg') top center;
	background-size: cover;
}

.trznica {
	background: url('../img/radiotrznica.jpg') top center;
	background-size: cover;
}

.clear {
	clear: both;
}

footer {
	&.home {
		font-size: emCalc(16);
		opacity: 0.5;
		background-repeat: no-repeat;
		background-position: 0 21px;
		color: #9a9a9a;
		padding: 10px 0;
		margin-top: -40px;
		height: 40px;
		font-family: 'Karlsen Round Semi bold';
		border-top: 0;
		a {
			color: #9a9a9a;
		}
		.row {
			max-width: 62.5em !important;
		}
		.dcl {
			margin: 4px 0;
			float: right;
			display: inline-block;
			text-indent: -9999px;
			background: url('../img/dcl.png');
			width: 25px;
			height: 15px;
		}
	}
	border-top: 1px solid #ddd;
	.row {
		max-width: 90% !important;
	}
	font-size: emCalc(16);
	background-repeat: no-repeat;
	background-position: 0 21px;
	color: #9a9a9a;
	padding: 15px 0;
	margin: 20px;
	height: 40px;
	font-family: 'Karlsen Round Semi bold';
	a {
		color: #9a9a9a;
	}
	.dcl {
		margin: 4px 0;
		float: right;
		display: inline-block;
		text-indent: -9999px;
		background: url('../img/dcl-dark.png');
		width: 25px;
		height: 15px;
	}
}

// O nama

h2.title {
	margin-top: 25px;
	color: $blue;
	text-align: center;
	font-family: 'Karlsen Round Bold';
	font-size: emCalc(36);
}

hr {
	width: 80%;
	margin: 25px auto;
	border: 0;
	height: 1px;
	background-image: linear-gradient(to right, rgba(39, 55, 87, 0), rgba(39, 55, 87, 0.75), rgba(39, 55, 87, 0));
}

.hidden {
	visibility: hidden;
}

.content-block {
	overflow:auto;
	width: 80%;
	margin: 40px auto;
	box-shadow: 0px 0px 3px rgba(0,0,0,.5);
	background-color: #fff;
	height: auto;
	padding: 30px;
	color: $blue;
	font-size: emCalc(14);
	.t {
		margin: 0;
		width: 200px;
	}
	h3 {
		font-family: 'Karlsen Round Semi bold';
		color: $blue;
	}
	.center-wrap {
		display: inline-block;
		vertical-align: middle;
	}
	.onama-text-wrap {
		float: right;
	}
	.img-wrap {
		display: inline-block;
		vertical-align: baseline;
		background-position: -32px;
		background-size: cover !important;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		float: left;
		margin-right: 30px;
	}
	.katel {
		background: url('../img/team/katel.jpg') -32px no-repeat;
	}
	.beta {
		background: url('../img/team/beta.jpg') -32px no-repeat;	
	}
	.zeljo {
		background: url('../img/team/zeljo.jpg') -72px no-repeat;	
	}
	.vesna {
		background: url('../img/team/vesna.jpg') -32px no-repeat;	
	}
	.goga {
		background: url('../img/team/goga.jpg') -32px no-repeat;	
	}
	.dijana {
		background: url('../img/team/dijana.jpg') -32px no-repeat;	
	}
}

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea {
	border-radius: 5px !important;
	border-color: $blue !important;
	border-width: 2px !important;
}

.nav_trigger {
	font-size: emCalc(24) !important;
	margin: 30px auto;
	cursor:pointer;
	color: $blue;
}

@media only screen and (max-width:40em) {
	.intro-video .intro_text h2 {
		margin-top: 80px !important;
		font-size: emCalc(55);
	}
	.home {
		nav {
			background: #fff;
			ul li a{
			color: $blue !important;
		}
		}
		.nav_trigger {
			color: #fff;
		}
		&.smaller {
			.nav_trigger {
				color: $blue;
			}
			ul li.active a {
				color: #fff !important;
			}
		}
	}
	.mobile-nav {
		display: none;
		li {
			width: 100%;
		}
	}
	.mobile_text {
		text-align: center;
		width: 100% !important;
		margin-top: 20px;
	}
	.mobile_image {
		width: 200px !important;
		margin: 0 auto;
		display: block !important;
	}
}